<template>
  <div>
    <ul class="reportDiv">
      <!--통합분석결과-->
      <li>
        <p class="styleTit wd00">통합 분석 결과</p>
        <div class="cobbResult div">
          <spine-rslt-compo :xrayMap="xrayMap.spine || {}" />
        </div>
        <div class="div">
          <risser-rslt-compo :xrayMap="xrayMap.spine || {}" />
          <div class="patient-info">
            <table class="patient-table">
              <tr>
                <th>환자번호</th>
                <td>{{ patient.patientSn }}</td>
              </tr>
              <tr>
                <th>환자명</th>
                <td>{{ patient.name }}</td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>{{ patient.birth }}</td>
              </tr>
              <tr>
                <th>생리학적 나이 (촬영일 기준)</th>
                <td>
                  {{
                    calculatedAgeAtFirstAnalysis(
                      patient.birth,
                      record.current.pictureDtStr
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th>성별</th>
                <td>
                  {{ patient.sex === 0 ? "남" : patient.sex === 1 ? "여" : "" }}
                </td>
              </tr>
              <tr>
                <th>기타사항</th>
                <td>{{ patient.otherDetails }}</td>
              </tr>
            </table>
          </div>
        </div>
      </li>

      <!-- 결과 요약 -->
      <li v-if="record.firstSpine">
        <p class="styleTit wd00">이전 분석 결과</p>
        <div class="div">
          <p class="litit">AI 기반 Cobb’s angle 분석 결과</p>
          <SpineCompo
            :record="record"
            :allImageLoaded="true"
            :isFirstRecord="record.isFirstSpine"
            :isReport="false"
          />
        </div>
        <div class="div">
          <p class="litit">AI 기반 Risser grade 분석 결과</p>
          <RisserCompo
            :record="record"
            :patient="patient"
            :allImageLoaded="true"
            :isFirstRecord="record.isFirstSpine"
            :isReport="false"
          />
        </div>
      </li>

      <!-- 상세 분석 내용 -->
      <li>
        <div class="div">
          <p class="litit">{{ READING_SPINE_NAME }} 분석 결과 추이 그래프</p>
          <div class="divi">
            <div class="graphBox">
              <GraphCompo
                :xrayType="'spine'"
                :xrayNo="xrayNo"
                :isReport="false"
                graphType="maxAngle"
                :width="'100%'"
              />
            </div>
          </div>
        </div>
        <div class="div">
          <p class="litit">{{ READING_RISSER_NAME }} 분석 결과 추이 그래프</p>
          <div class="divi">
            <div class="graphBox">
              <GraphCompo
                :xrayType="'hands'"
                :xrayNo="xrayNo"
                :isReport="false"
                graphType="risser"
                :width="'100%'"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <button
      class="totalReportView btn solid small"
      @click="openReport($event)"
      v-ripple
    >
      통합 리포트 보기
    </button>

    <v-dialog v-model="reportVisible" persistent>
      <Report
        :onClick="
          () => {
            reportVisible = false;
          }
        "
        :xrayNo="xrayNo"
        @closeReport="closeReport"
        :key="componentKey"
      />
    </v-dialog>
  </div>
</template>

<script>
import API_REPORT from "../API/record/report.js";
import spineRsltCompo from "./spineRsltCompo.vue";
import risserRsltCompo from "./risserRsltCompo.vue";

export default {
  components: {
    Report: () => import("../views/user/report/totReport.vue"),
    GraphCompo: () => import("@/components/graphCompo.vue"),
    SpineCompo: () => import("@/components/spineReportCompo.vue"),
    RisserCompo: () => import("@/components/risserReportCompo.vue"),
    spineRsltCompo,
    risserRsltCompo,
  },
  props: {
    xrayMap: {
      default() {
        return {
          spine: {},
          hands: {},
        };
      },
    },
    xrayNo: {
      default: "",
    },
  },
  data() {
    return {
      reportVisible: false,
      componentKey: 0,
      record: {
        current: {
          spine: {},
          hands: {},
        },
        firstSpine: {},
        firstHands: {},
        prev: {},
        isFirstSpine: false,
        isFirstHands: false,
      },
      patient: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.setPatientInfo();
  },
  async mounted() {
    await this.initData(this.xrayNo);
    this.getDate();
    this.setPatientInfo();
  },
  destroyed() {},
  methods: {
    getDate() {
      let today = new Date();
      let year = today.getFullYear();
      let month = String(today.getMonth() + 1).padStart(2, "0");
      let day = String(today.getDate()).padStart(2, "0");
      this.crtYear = year;
      this.today = year + month + day;
    },
    async initData(xrayNo) {
      if (this.isGettingItems) {
        return;
      }
      this.isGettingItems = true;
      let reportRes = await API_REPORT.request(xrayNo);
      if (reportRes.isSuccess) {
        this.record.current = reportRes.currentRecord;
        this.record.isFirstSpine = reportRes.isFirstSpine;
        this.record.firstSpine = reportRes.firstSpineRecord;
        this.record.isFirstHands = reportRes.isFirstHands;
        this.record.firstHands = reportRes.firstHandsRecord;
        this.record.prev = reportRes.prevRecord;

        this.roundAngles(this.record.current.spine);
        this.roundAngles(this.record.firstSpine);
        this.roundAngles(this.record.prev);

        if (this.record.firstSpine)
          this.record.firstSpine.atTimeAge = this.calculatedAgeAtFirstAnalysis(
            this.patient.birth,
            this.record.firstSpine.pictureDtStr
          );
        if (this.record.prev)
          this.record.prev.atTimeAge = this.calculatedAgeAtFirstAnalysis(
            this.patient.birth,
            this.record.prev.pictureDtStr
          );
        if (this.record.current)
          this.record.current.atTimeAge = this.calculatedAgeAtFirstAnalysis(
            this.patient.birth,
            this.record.current.pictureDtStr
          );

        //console.log('Record:', this.record);
      } else {
        this.showPopup(reportRes.errorMsg, reportRes.status);
      }
      this.isGettingItems = false;
    },
    setPatientInfo() {
      const patientInfo = JSON.parse(localStorage.getItem("targetInfo"));
      if (patientInfo) this.patient = patientInfo;
    },
    openReport(event) {
      event.preventDefault();
      this.componentKey += 1;
      this.reportVisible = true;
    },
    closeReport() {
      this.reportVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";

.v-application {
  .blue,
  .red {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .totalReportView {
    position: absolute;
    right: 40px;
    top: 40px;
  }
}

.resultImages {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.spineResult {
  width: 30%;
  /* 좌측 이미지 가로 크기를 50%로 설정 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.handsResult {
  width: 50%;
  /* 우측 이미지 가로 크기를 50%로 설정 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.handsResult .handsContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* flex-start로 설정하여 공백 제거 */
  height: 100%;
}

.handsResult .xrayImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65%;
  /* 우측 X-ray 이미지 높이를 65%로 설정 */
}

.handsResult .imgW {
  width: 100%;
  height: 100%;
}

.handsResult .imgW img {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.patientInfo {
  width: 100%;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 4px;
  margin-top: 0;
  /* margin-top 제거 */
}

.patientInfo p {
  font-weight: bold;
}

.patientInfo ul {
  list-style: none;
  padding: 0;
}

.patientInfo ul li {
  margin-bottom: 5px;
}

.reportDiv::v-deep {
  gap: 28px;
  .resultBox {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 8px;

    ul.imgBox {
      width: calc(66% - 16px);
      display: flex;
      gap: 16px;

      li {
        width: 50%;
      }
    }

    .imgW {
      position: relative;
      padding-top: 140%;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    div.imgBox {
      width: calc(33% - 16px);
      margin-top: 0;
    }

    .date {
      margin-top: 8px;
      font-size: 1.2rem;
      text-align: center;

      &.em {
        color: var(--color-s80);
      }
    }

    .txtList {
      width: 33%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.firstTextList {
        width: 66%;
      }

      > li {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .em {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 16px;
          background: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          width: 100%;

          p {
            font-size: 1.4rem;
          }

          .rightTxt {
            color: var(--color-s80);
            font-weight: 600;
            font-size: 1.6rem;
          }
        }

        &.tit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.2rem;

          .stxt {
            font-size: 1rem;
            opacity: 0.8;
          }
        }

        .addEm {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .em {
            padding: 8px 16px;

            p {
              font-size: 1.2rem;
            }

            .rightTxt {
              color: var(--color-t80);
            }
          }
        }

        .red,
        .blue {
          background: transparent !important;
        }
      }
    }

    .resultInfo {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        font-size: 1.2rem;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .divi {
    display: flex;
    gap: 16px;
    margin-top: 8px;

    .lBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: center;
      width: 150px;

      .imgW {
        position: relative;
        width: 100%;
        height: 200px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        overflow: hidden;

        img {
          position: absolute;
          max-width: 100%;
          max-height: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .txtW {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;

        .date {
          color: var(--color-s80);
        }
      }

      .graphBox {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        min-height: 250px;
        width: 100%;
      }
    }

    // .rBox {
    //   width: calc(100% - 166px);
    // }
  }

  .flextit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stxt {
      font-size: 1.2rem;
      opacity: 0.8;
    }
  }

  #apexchartsvuechart-example {
    .apexcharts-xaxis-texts-g > text,
    .apexcharts-yaxis-texts-g > text {
      fill: #fff;
    }

    .apexcharts-legend-series > span.apexcharts-legend-text {
      color: #fff !important;
    }
  }
}
::v-deep .cobbResult .xrayImg .imgW {
  padding-top: calc(100vh - 600px) !important;
}
.patient-info {
  margin-top: 40px;
}
</style>
